// src/components/FileDownloadPage.jsx

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography, Alert } from "@mui/material";
import DecryptingText from "./DecryptingText"; // Adjust the path if necessary
import { db } from "../context/firebase"; // Import Firestore
import { getDoc, setDoc, doc } from "firebase/firestore"; // Import setDoc and doc
const FileDownloadPage = () => {
  const [decrypting, setDecrypting] = useState(true);
  const [error, setError] = useState("");
  const [downloadURL, setDownloadURL] = useState(null);

  const location = useLocation();

  // Define allowed domains
  const allowedDomains = [
    "firebasestorage.googleapis.com",
    "shieldvault.us",
    "localhost" // Add any other trusted domains here
  ];

  useEffect(() => {
    const fetchDownloadURL = async (token) => {
      try {
        // Query Firestore for the document with the given token
        const filesRef = doc(db, "files", token); // Adjust collection and document structure as needed
        const fileSnap = await getDoc(filesRef);

        if (fileSnap.exists()) {
          const fileData = fileSnap.data();
          const url = fileData.url;
          const expiry = fileData.expiry; // Retrieve expiration timestamp

          // Check if the link is a "burn after reading" type
          if (fileData.expiry === null) {
            // Proceed to download and invalidate the link
            setDownloadURL(url);
            setTimeout(async () => {
              setDecrypting(false);
              // Redirect to the download URL
              window.location.href = url;

              // Invalidate the token after download (for "burn" links)
              await setDoc(doc(db, "files", token), {
                ...fileData,
                token: null, // Remove the token to invalidate the link
              }, { merge: true });
            }, 3000); // 3-second delay
          } else {
            // Regular expiry-based link
            const currentTime = Date.now();
            if (currentTime < expiry) {
              setDownloadURL(url);
              // Simulate decrypting delay
              setTimeout(() => {
                setDecrypting(false);
                // Redirect to the download URL
                window.location.href = url;
              }, 3000); // 3-second delay
            } else {
              throw new Error("Download link has expired.");
            }
          }
        } else {
          throw new Error("File not found.");
        }
      } catch (err) {
        console.error("Download link validation error:", err);
        setError("Invalid or unauthorized download link.");
      }
    };

    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    if (token) {
      fetchDownloadURL(token);
    } else {
      setError("Invalid or expired download link.");
    }
  }, [location.search]);

  return (
    <Box
      sx={{
        py: 6,
        px: 2,
        minHeight: "100vh",
        bgcolor: "background.default",
        color: "text.primary",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {error ? (
        <Alert severity="error">{error}</Alert>
      ) : decrypting ? (
        <DecryptingText text="DECRYPTING YOUR FILE... PLEASE WAIT" />
      ) : (
        // This block may not be necessary as redirection happens in setTimeout
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            sx={{
              mb: 4,
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 700,
              color: "#e63946",
            }}
          >
            Your File is Ready!
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default FileDownloadPage;
