import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { GiTrident } from "react-icons/gi";
import { FaUserSecret } from "react-icons/fa";
import ThemeToggle from "../components/ThemeToggle";
import { useAuth } from "../context/AuthContext";
import { useClient } from "../context/ClientContext"; 
import { db } from "../context/firebase";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";

function Header() {
  const theme = useTheme();
  const { logout, currentUser } = useAuth();
  const { selectedClient, setSelectedClient } = useClient();
  const [clients, setClients] = useState([]);

  useEffect(() => {
    if (!currentUser) return;

    const fetchUserClients = async () => {
      try {
        // Fetch user record to get role and assigned domain
        const userDocRef = doc(db, "allowedusers", currentUser.email);
        const userDocSnap = await getDoc(userDocRef);

        if (!userDocSnap.exists()) {
          console.warn("User record not found.");
          return;
        }

        const userData = userDocSnap.data();
        const userRole = userData.role;
        const userDomain = userData.domain;

        let clientList = [];

        if (userRole === "admin") {
          // Admins see all clients
          const querySnapshot = await getDocs(collection(db, "clients"));
          clientList = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
        } else {
          // Viewers only see their assigned domain
          const clientQuerySnapshot = await getDocs(collection(db, "clients"));
          clientList = clientQuerySnapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .filter((client) => client.domain === userDomain); 
        }

        setClients(clientList);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    fetchUserClients();
  }, [currentUser]);

  const handleLogoutClick = async () => {
    const confirmed = window.confirm("Are you sure you want to log out?");
    if (confirmed) {
      try {
        await logout();
      } catch (error) {
        console.error("Error logging out:", error);
      }
    }
  };

  if (!currentUser) {
    return null;
  }

  return (
    <AppBar
      position="static"
      sx={{
        bgcolor: "background.paper",
        color: "text.primary",
        boxShadow: "none",
        borderBottom: `2px solid ${
          theme.palette.mode === "dark" ? "rgba(255,255,255,0.1)" : "rgba(0,0,0,0.1)"
        }`,
      }}
    >
      <Toolbar>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <GiTrident
            style={{
              fontSize: "1.8rem",
              color: theme.palette.mode === "dark" ? "#e63946" : "#ff4500",
            }}
          />
          <Typography
            variant="h6"
            sx={{
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: 700,
              fontSize: "1.5rem",
              background:
                theme.palette.mode === "dark"
                  ? "linear-gradient(135deg, #e63946, #f4a261)"
                  : "linear-gradient(135deg, #ff7f50, #ff4500)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Shield Vault
          </Typography>
        </Box>

        <Box sx={{ ml: "auto", display: "flex", alignItems: "center", gap: 2 }}>
          <FormControl
            sx={{
              minWidth: 200,
              bgcolor: theme.palette.mode === "dark"
                ? "rgba(255,255,255,0.1)"
                : "rgba(0,0,0,0.05)",
              borderRadius: 1,
              color: "text.primary",
            }}
            size="small"
          >
            <InputLabel sx={{ color: "text.secondary" }}>Select Client</InputLabel>
            <Select
              value={selectedClient || ""}
              onChange={(e) => setSelectedClient(e.target.value)}
              displayEmpty
              sx={{
                color: "text.primary",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.mode === "dark" ? "#e63946" : "#ff4500",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.mode === "dark" ? "#f4a261" : "#ff7f50",
                },
              }}
            >
              {clients.length === 0 ? (
                <MenuItem value="" disabled>
                  No clients available
                </MenuItem>
              ) : (
                clients.map((client) => (
                  <MenuItem key={client.id} value={client.domain}>
                    {client.name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>

          <ThemeToggle />

          <IconButton
            sx={{
              fontSize: "1.8rem",
              color: theme.palette.mode === "dark" ? "#f4a261" : "#ff4500",
            }}
            aria-label="Logout"
            onClick={handleLogoutClick}
          >
            <FaUserSecret />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
