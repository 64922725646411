// src/pages/SecureFileUpload.js

import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Alert,
  Snackbar,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
} from "@mui/material";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { motion } from "framer-motion";
import { FileDrop } from "react-file-drop";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { storage, db } from "../context/firebase"; // Import Firestore
import { setDoc, doc, serverTimestamp } from "firebase/firestore"; // Firestore functions
import { v4 as uuidv4 } from "uuid"; // Import UUID

// Import your DecryptingText component
import DecryptingText from "../components/DecryptingText";

const SecureFileUpload = () => {
  const [files, setFiles] = useState([]);
  const [downloadLinks, setDownloadLinks] = useState([]);
  const [expiry, setExpiry] = useState("24h");
  const [showAlert, setShowAlert] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [error, setError] = useState("");

  // State for the decrypt animation
  const [showDecryption, setShowDecryption] = useState(false);

  const navigate = useNavigate();

  const handleFileChange = (selectedFiles) => {
    if (selectedFiles) {
      const selected = Array.from(selectedFiles).slice(0, 2); // Allow up to 2 files
      setFiles(selected);
      setError(""); // Clear any previous errors
    }
  };

  // Helper function to convert expiry string to milliseconds
  const getExpiryDuration = (expiryOption) => {
    switch (expiryOption) {
      case "24h":
        return 24 * 60 * 60 * 1000; // 24 hours
      case "48h":
        return 48 * 60 * 60 * 1000; // 48 hours
      case "3d":
        return 3 * 24 * 60 * 60 * 1000; // 3 days
      case "5d":
        return 5 * 24 * 60 * 60 * 1000; // 5 days
      case "burn":
        return null; // Burn after reading (handled separately)
      default:
        return 24 * 60 * 60 * 1000; // Default to 24 hours
    }
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setError("Please select files to upload.");
      return;
    }

    // Reset states
    setDownloadLinks([]);
    setShowAlert(false);
    setShowDecryption(false);

    const uploadedLinks = [];

    for (const file of files) {
      try {
        const token = uuidv4(); // Generate unique token
        const storageRef = ref(storage, `uploads/${token}_${file.name}`); // Use token in the storage path
        const uploadTask = uploadBytesResumable(storageRef, file);

        // Upload the file
        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            null,
            (error) => {
              console.error("Upload error:", error);
              setError("Failed to upload files. Please try again.");
              reject(error);
            },
            async () => {
              const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

              // Calculate expiration timestamp
              let expirationTimestamp = null;
              if (expiry !== "burn") {
                const duration = getExpiryDuration(expiry);
                expirationTimestamp = Date.now() + duration;
              }

              // Store file metadata in Firestore with token as the document ID
              await setDoc(doc(db, "files", token), {
                name: file.name,
                url: downloadURL,
                expiry: expirationTimestamp, // Store expiration timestamp
                createdAt: serverTimestamp(),
              });

              // Generate a secure download link using the token
              const secureDownloadLink = `${window.location.origin}/download?token=${token}`;
              uploadedLinks.push({ token: token, name: file.name, link: secureDownloadLink });
              resolve();
            }
          );
        });
      } catch (err) {
        console.error("Error uploading file:", err);
        setError("An error occurred during upload.");
      }
    }

    setDownloadLinks(uploadedLinks);
    setShowAlert(true);
    // Start the decrypting animation
    setShowDecryption(true);

    // After decrypting animation duration, hide the animation to show download links
    setTimeout(() => {
      setShowDecryption(false);
      console.log("Decryption completed. Download links are now visible.");
    }, 3000); // 3-second delay
  };

  const handleCopyLink = (url) => {
    navigator.clipboard.writeText(url);
    setShowSnackbar(true);
  };

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
  };

  return (
    <Box
      sx={{
        py: 6,
        px: 2,
        minHeight: "100vh",
        bgcolor: "background.default",
        color: "text.primary",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowY: "auto",
      }}
    >
      {/* Back to Dashboard Button */}
      <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-start", mb: 2 }}>
        <IconButton onClick={() => navigate("/")} sx={{ color: "#e63946" }}>
          <ArrowBackIcon sx={{ fontSize: "2rem" }} />
        </IconButton>
      </Box>

      {/* Title */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        style={{ textAlign: "center" }}
      >
        <Typography
          variant="h3"
          sx={{
            mb: 4,
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 700,
            background: "linear-gradient(135deg, #e63946, #f4a261)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Secure File Upload
        </Typography>
      </motion.div>

      {/* File Drop Area */}
      <FileDrop onDrop={(files, event) => handleFileChange(files)}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: 4,
            border: "3px dashed #e63946",
            borderRadius: "12px",
            width: "100%",
            maxWidth: "600px",
            minHeight: "200px",
            textAlign: "center",
            background:
              "linear-gradient(135deg, rgba(230,57,70,0.1), rgba(244,162,97,0.1))",
            "&:hover": {
              background:
                "linear-gradient(135deg, rgba(230,57,70,0.2), rgba(244,162,97,0.2))",
              cursor: "pointer",
            },
            mb: 3,
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#e63946",
              fontWeight: "bold",
              mb: 2,
              fontFamily: "'Montserrat', sans-serif",
            }}
          >
            Drag & Drop Files Here
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "#aaa",
              fontFamily: "'Roboto', sans-serif",
              maxWidth: "400px",
            }}
          >
            Only .pdf, .doc(x), .xls(x), .ppt(x), .csv, .txt, & .zip files are
            allowed (max 50MB).
          </Typography>

          {files.length > 0 && (
            <Box
              sx={{
                mt: 2,
                p: 2,
                border: "1px solid #e63946",
                borderRadius: "8px",
                width: "100%",
                maxWidth: "90%",
                bgcolor: "rgba(230,57,70,0.05)",
              }}
            >
              <Typography variant="body1" sx={{ color: "#ccc", mb: 1 }}>
                Selected Files:
              </Typography>
              <ul style={{ margin: 0, paddingLeft: "1.5em", textAlign: "left" }}>
                {files.map((file, index) => (
                  <li key={index} style={{ marginBottom: "4px" }}>
                    <Typography variant="body2" sx={{ color: "#fff" }}>
                      {file.name}
                    </Typography>
                  </li>
                ))}
              </ul>
            </Box>
          )}
        </Box>
      </FileDrop>

      {/* Select Files Button */}
      <Box sx={{ display: "flex", justifyContent: "center", mb: 2, width: "100%", maxWidth: "300px" }}>
        <Button
          variant="contained"
          component="label"
          fullWidth
          sx={{
            backgroundColor: "#e63946",
            "&:hover": { backgroundColor: "#c72c3e" },
          }}
        >
          Select Files
          <input
            type="file"
            hidden
            multiple
            onChange={(e) => handleFileChange(e.target.files)}
          />
        </Button>
      </Box>

      {/* Expiry Dropdown */}
      <Box sx={{ display: "flex", justifyContent: "center", mb: 3, width: "100%", maxWidth: "300px" }}>
        <FormControl fullWidth>
          <InputLabel>Link Expiry</InputLabel>
          <Select
            value={expiry}
            onChange={(e) => setExpiry(e.target.value)}
            sx={{ color: "#fff" }}
          >
            <MenuItem value="24h">24 Hours</MenuItem>
            <MenuItem value="48h">48 Hours</MenuItem>
            <MenuItem value="3d">3 Days</MenuItem>
            <MenuItem value="5d">5 Days</MenuItem>
            <MenuItem value="burn">Burn after reading</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Upload Button */}
      <Box sx={{ display: "flex", justifyContent: "center", mb: 3, width: "100%", maxWidth: "300px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          fullWidth
          sx={{
            backgroundColor: "#2196f3",
            "&:hover": { backgroundColor: "#1976d2" },
          }}
        >
          Upload
        </Button>
      </Box>

      {/* Display Errors */}
      {error && (
        <Box sx={{ mb: 2, width: "100%", maxWidth: "600px" }}>
          <Alert severity="error">{error}</Alert>
        </Box>
      )}

      {/* Decryption Animation */}
      {showDecryption && (
        <Box sx={{ mt: 3 }}>
          <DecryptingText
            text="ENCRYPTING DOWNLOAD LINKS... PLEASE WAIT"
            // You can customize this text as needed
          />
        </Box>
      )}

      {/* Download Links */}
      {downloadLinks.length > 0 && !showDecryption && (
        <Box sx={{ mt: 3, maxWidth: "600px", width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography variant="h6" sx={{ mb: 2, color: "#f4a261" }}>
            Secure Download Links:
          </Typography>
          {downloadLinks.map((file, index) => (
            <Box
              key={index}
              sx={{
                mt: 2,
                display: "flex",
                flexDirection: { xs: "column", sm: "row" }, // Stack on small screens
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
              }}
            >
              <Typography variant="body2" sx={{ color: "#fff", textAlign: "center" }}>
                <a
                  href={file.link} // Use the secureDownloadLink with token
                  style={{ color: "#e63946", textDecoration: "underline" }}
                >
                  {file.name}
                </a>
              </Typography>
              <Button
                variant="outlined"
                onClick={() => handleCopyLink(file.link)}
                sx={{
                  borderColor: "#e63946",
                  color: "#e63946",
                  "&:hover": {
                    borderColor: "#c72c3e",
                    color: "#c72c3e",
                  },
                }}
              >
                Copy Link
              </Button>
            </Box>
          ))}
        </Box>
      )}

      {/* Snackbar for "Copied to clipboard" */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Copied to clipboard"
      />

      {/* Alert for Successful Upload */}
      {showAlert && (
        <Snackbar
          open={showAlert}
          autoHideDuration={6000}
          onClose={() => setShowAlert(false)}
        >
          <Alert severity="success" onClose={() => setShowAlert(false)}>
            Files uploaded successfully. Encryption in progress...
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

// Ensure that the export statement is **outside** the component function
export default SecureFileUpload;
