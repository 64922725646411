import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CircularProgress,
  TextField,
  List,
  ListItem,
  IconButton,
  Alert,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { motion } from "framer-motion";
import { useAuth } from "../context/AuthContext";
import { useClient } from "../context/ClientContext";
import Chart from "react-apexcharts";
import {
  getDoc,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../context/firebase";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const CLOUD_FUNCTION_URL = "https://proxysnusbase-ydqv7zdweq-uc.a.run.app";

const DarkWebMonitoring = () => {
  const theme = useTheme();
  const { currentUser } = useAuth();
  const { selectedClient } = useClient(); // Client is selected in the header
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [anchorPoints, setAnchorPoints] = useState([]);
  const [newAnchorPoint, setNewAnchorPoint] = useState("");
  const [error, setError] = useState("");
  const [userRole, setUserRole] = useState("");

  // Local state for scan history & latest scan results (persisted in Firestore)
  const [scanHistory, setScanHistory] = useState([]);
  const [scanResults, setScanResults] = useState(null);

  // Create a safe version of scanHistory to ensure every metric is defined
  const safeScanHistory = scanHistory.map((metric) => ({
    date: metric.date || "",
    passwords: metric.passwords ?? 0,
    usernames: metric.usernames ?? 0,
    ipAddresses: metric.ipAddresses ?? 0,
    emails: metric.emails ?? 0,
    totalCount: metric.totalCount ?? 0,
  }));

  // Determine the latest metric from scanHistory (if any)
  const latestMetric =
    safeScanHistory.length > 0
      ? safeScanHistory[safeScanHistory.length - 1]
      : { totalCount: 0, passwords: 0, usernames: 0, ipAddresses: 0, emails: 0 };

  // Chart data (line chart) using safeScanHistory
  const chartData = {
    series: [
      {
        name: "Emails",
        data: safeScanHistory.map((metric) => metric.emails),
      },
      {
        name: "IP Addresses",
        data: safeScanHistory.map((metric) => metric.ipAddresses),
      },
      {
        name: "Passwords",
        data: safeScanHistory.map((metric) => metric.passwords),
      },
      {
        name: "Usernames",
        data: safeScanHistory.map((metric) => metric.usernames),
      },
      {
        name: "Total Count",
        data: safeScanHistory.map((metric) => metric.totalCount),
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 350,
        toolbar: { show: false },
      },
      xaxis: {
        categories: safeScanHistory.map((metric) => metric.date),
      },
      stroke: {
        curve: "smooth",
      },
      markers: {
        size: 4,
      },
      dataLabels: { enabled: false },
      tooltip: {
        y: {
          formatter: (val) => val,
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
      },
      theme: {
        mode: theme.palette.mode,
      },
    },
  };

  // Fetch client data (anchor points & persisted metrics) from Firestore
  useEffect(() => {
    const fetchClientData = async () => {
      setIsLoading(true);
      try {
        if (!currentUser) {
          setError("Access Denied");
          return;
        }
        // Verify user details
        const userDocRef = doc(db, "allowedusers", currentUser.email);
        const userDocSnap = await getDoc(userDocRef);
        if (!userDocSnap.exists()) {
          setError("Access Denied");
          return;
        }
        const userData = userDocSnap.data();
        const userDomain = userData.domain;
        const role = userData.role;
        setUserRole(role);
        if (role !== "admin" && (!selectedClient || selectedClient !== userDomain)) {
          setError("Access Denied");
          return;
        }
        // Listen to the client document in real time
        const clientRef = doc(db, "clients", selectedClient);
        const unsubscribe = onSnapshot(
          clientRef,
          (docSnap) => {
            if (docSnap.exists()) {
              const clientData = docSnap.data();
              setAnchorPoints(clientData.anchorPoints || []);
              setScanHistory(clientData.metrics || []); // Load persisted metrics
            } else {
              setAnchorPoints([]);
              setScanHistory([]);
            }
            setIsLoading(false);
          },
          (error) => {
            setError("Failed to fetch client data.");
            setIsLoading(false);
          }
        );
        return () => unsubscribe();
      } catch (error) {
        setError("Failed to fetch client data.");
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedClient) {
      fetchClientData();
    } else {
      setIsLoading(false);
      setAnchorPoints([]);
      setScanHistory([]);
    }
  }, [selectedClient, currentUser]);

  // Run a scan using all anchor points & search types; persist new metrics in Firestore
  const handleRunScan = async () => {
    if (anchorPoints.length === 0) {
      alert("No anchor points available. Please add anchor points first.");
      return;
    }
    if (!currentUser) {
      setError("You must be logged in to perform this scan.");
      return;
    }
    setIsLoading(true);
    setError("");
    try {
      // Define all search types (as in your Recon)
      const allTypes = ["email", "username", "password", "lastip", "name", "_domain"];

      // Initialize aggregated counts for metrics we care about
      const aggregatedCounts = {
        totalCount: 0,
        passwords: 0,
        usernames: 0,
        ipAddresses: 0,
        emails: 0,
      };

      // Get Firebase ID token once
      const idToken = await currentUser.getIdToken();

      // Run a scan for each anchor point
      const scanPromises = anchorPoints.map((anchor) => {
        const payload = {
          terms: allTypes.map(() => anchor),
          types: allTypes,
        };
        console.log("Payload for", anchor, ":", payload);
        return axios.post(CLOUD_FUNCTION_URL, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        });
      });

      const responses = await Promise.all(scanPromises);

      // Flatten breach results and aggregate counts
      responses.forEach((response) => {
        if (response.data && response.data.results) {
          const breaches = Object.values(response.data.results).flat();
          breaches.forEach((item) => {
            aggregatedCounts.totalCount += 1;
            if (item.email) aggregatedCounts.emails += 1;
            if (item.username) aggregatedCounts.usernames += 1;
            if (item.password || item.hash) aggregatedCounts.passwords += 1;
            if (item.lastip) aggregatedCounts.ipAddresses += 1;
          });
        }
      });

      console.log("Aggregated Results:", aggregatedCounts);
      const newMetric = {
        date: new Date().toISOString().split("T")[0],
        emails: aggregatedCounts.emails,
        usernames: aggregatedCounts.usernames,
        ipAddresses: aggregatedCounts.ipAddresses,
        passwords: aggregatedCounts.passwords,
        totalCount: aggregatedCounts.totalCount,
      };
      console.log("New Scan Metric:", newMetric);

      // Save the new metric in Firestore using arrayUnion
      await updateDoc(doc(db, "clients", selectedClient), {
        metrics: arrayUnion(newMetric),
      });
      // Update local state with the new metric
      setScanResults(newMetric);
      setScanHistory((prev) => [...prev, newMetric]);

      alert("Scan completed successfully!");
    } catch (error) {
      console.error("Error during scan:", error);
      if (error.response && error.response.data && error.response.data.message) {
        setError(`Scan failed: ${error.response.data.message}`);
      } else {
        setError("An unexpected error occurred during the scan.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Add an anchor point and persist it in Firestore
  const handleAddAnchorPoint = async () => {
    if (!newAnchorPoint.trim()) return;
    try {
      await updateDoc(doc(db, "clients", selectedClient), {
        anchorPoints: arrayUnion(newAnchorPoint.trim()),
      });
      setNewAnchorPoint("");
    } catch (error) {
      console.error("Error adding anchor point:", error);
      setError("Failed to add anchor point.");
    }
  };

  // Remove an anchor point and update Firestore
  const handleRemoveAnchorPoint = async (anchor) => {
    try {
      await updateDoc(doc(db, "clients", selectedClient), {
        anchorPoints: arrayRemove(anchor),
      });
    } catch (error) {
      console.error("Error removing anchor point:", error);
      setError("Failed to remove anchor point.");
    }
  };

  return (
    <Box sx={{ p: 4, minHeight: "100vh", bgcolor: "background.default", color: "text.primary" }}>
      {/* Back Button */}
      <IconButton onClick={() => navigate("/")} sx={{ alignSelf: "flex-start", mb: 2 }}>
        <ArrowBackIcon sx={{ fontSize: "2rem", color: "#e63946" }} />
      </IconButton>

      {/* Header */}
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        style={{ textAlign: "center", marginBottom: "2rem" }}
      >
        <Typography
          variant="h3"
          sx={{
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 700,
            background: "linear-gradient(135deg, #e63946, #f4a261)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Dark Web Monitoring
        </Typography>
        <Typography variant="body1" sx={{ mt: 1, color: "#aaa", fontSize: "1rem" }}>
          Track and monitor exposed data for your domain over time.
        </Typography>
        <Typography variant="h6" sx={{ mt: 1 }}>
          Monitoring Domain: {selectedClient}
        </Typography>
      </motion.div>

      {/* Loading State */}
      {isLoading ? (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "300px" }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {error && (
            <Alert severity="error" sx={{ mb: 4, maxWidth: "600px", mx: "auto" }}>
              {error}
            </Alert>
          )}

          {/* Metrics Cards */}
          <Grid container spacing={3}>
            {/* Total Anchor Points */}
            <Grid item xs={12} md={4}>
              <Card sx={{ bgcolor: "background.paper", boxShadow: "0 4px 12px rgba(0,0,0,0.2)", borderRadius: "12px", textAlign: "center" }}>
                <CardContent>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>Total Anchor Points</Typography>
                  <Typography variant="h3" sx={{ mt: 2, color: "primary.main", fontWeight: 700 }}>
                    {anchorPoints.length}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {/* Total Scans Run */}
            <Grid item xs={12} md={4}>
              <Card sx={{ bgcolor: "background.paper", boxShadow: "0 4px 12px rgba(0,0,0,0.2)", borderRadius: "12px", textAlign: "center" }}>
                <CardContent>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>Total Scans Run</Typography>
                  <Typography variant="h3" sx={{ mt: 2, color: "primary.main", fontWeight: 700 }}>
                    {scanHistory.length}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            {/* Latest Exposed Records */}
            <Grid item xs={12} md={4}>
              <Card sx={{ bgcolor: "background.paper", boxShadow: "0 4px 12px rgba(0,0,0,0.2)", borderRadius: "12px", textAlign: "center" }}>
                <CardContent>
                  <Typography variant="h5" sx={{ fontWeight: "bold" }}>Latest Exposed Records</Typography>
                  <Typography variant="h3" sx={{ mt: 2, color: "primary.main", fontWeight: 700 }}>
                    {latestMetric.totalCount}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Detailed Metrics */}
            <Grid item xs={12} md={3}>
              <Card sx={{ bgcolor: "background.paper", boxShadow: "0 4px 12px rgba(0,0,0,0.2)", borderRadius: "12px", textAlign: "center" }}>
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>Passwords</Typography>
                  <Typography variant="h4" sx={{ mt: 1, color: "secondary.main", fontWeight: 700 }}>
                    {latestMetric.passwords}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card sx={{ bgcolor: "background.paper", boxShadow: "0 4px 12px rgba(0,0,0,0.2)", borderRadius: "12px", textAlign: "center" }}>
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>Usernames</Typography>
                  <Typography variant="h4" sx={{ mt: 1, color: "secondary.main", fontWeight: 700 }}>
                    {latestMetric.usernames}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card sx={{ bgcolor: "background.paper", boxShadow: "0 4px 12px rgba(0,0,0,0.2)", borderRadius: "12px", textAlign: "center" }}>
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>IP Addresses</Typography>
                  <Typography variant="h4" sx={{ mt: 1, color: "secondary.main", fontWeight: 700 }}>
                    {latestMetric.ipAddresses}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={3}>
              <Card sx={{ bgcolor: "background.paper", boxShadow: "0 4px 12px rgba(0,0,0,0.2)", borderRadius: "12px", textAlign: "center" }}>
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>Emails</Typography>
                  <Typography variant="h4" sx={{ mt: 1, color: "secondary.main", fontWeight: 700 }}>
                    {latestMetric.emails}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Chart Section (Historical View) */}
          <Box sx={{ mt: 4 }}>
            {safeScanHistory.length > 0 ? (
              <Chart options={chartData.options} series={chartData.series} type="line" height={350} />
            ) : (
              <Typography variant="h6" align="center" color="text.secondary">
                No scan history available.
              </Typography>
            )}
          </Box>

          {/* Manage Anchor Points */}
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Manage Anchor Points</Typography>
            <Typography variant="h6" sx={{ mb: 2 }}>Monitoring Domain: {selectedClient}</Typography>
            <TextField
              value={newAnchorPoint}
              onChange={(e) => setNewAnchorPoint(e.target.value)}
              placeholder="Add an anchor point"
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                endAdornment: (
                  <IconButton color="primary" onClick={handleAddAnchorPoint} disabled={!newAnchorPoint.trim()}>
                    <AddCircleOutlineIcon />
                  </IconButton>
                ),
              }}
            />
            <List>
              {anchorPoints.map((point, index) => (
                <ListItem
                  key={index}
                  secondaryAction={
                    <IconButton edge="end" onClick={() => handleRemoveAnchorPoint(point)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  {point}
                </ListItem>
              ))}
            </List>
          </Box>

          {/* Run Scan Button */}
          <Box sx={{ textAlign: "center", mt: 4 }}>
            <Button
              variant="contained"
              onClick={handleRunScan}
              sx={{ bgcolor: "primary.main", "&:hover": { bgcolor: "primary.dark" }, fontWeight: "bold" }}
              disabled={isLoading}
            >
              {isLoading ? "Running Scan..." : "Run Scan"}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default DarkWebMonitoring;
