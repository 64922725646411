// CTM.js
import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Snackbar,
  Chip,
  Tooltip,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { motion } from "framer-motion";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ScheduleScanModal from "../components/ScheduleScanModal";
import DecryptingText from "../components/DecryptingText";

// Import useAuth to access currentUser
import { useAuth } from "../context/AuthContext";

// Define source color mapping for a distinct look
const sourceColors = {
  reddit: "#FF4500",         // Reddit orange
  shodan: "#007BFF",         // Shodan blue
  hunter_domain: "#28A745",  // Green
  hunter_email: "#FFC107",   // Amber
  google: "#4285F4",         // Google blue
  intelx: "#6A1B9A",         // Purple
  default: "#e63946",        // Default red
};

// New component that displays result details nicely without a dropdown
const ResultRow = ({ source, result }) => {
  const color = sourceColors[source] || sourceColors.default;
  return (
    <TableRow style={{ borderLeft: `4px solid ${color}` }}>
      <TableCell sx={{ width: "20%", color: color, fontWeight: "bold" }}>
        {source}
      </TableCell>
      <TableCell>
        <Typography variant="h6">
          {result.title || result.value || result.ip || "N/A"}
        </Typography>
        {result.url && (
          <Typography variant="body2">
            <a
              href={result.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: color, textDecoration: "underline" }}
            >
              Visit Link
            </a>
          </Typography>
        )}
        <Box
          sx={{
            mt: 1,
            p: 1,
            backgroundColor: "#f8f8f8",
            borderRadius: "4px",
          }}
        >
          {Object.entries(result).map(([key, value]) => (
            <Typography key={key} variant="body2" sx={{ color: "#333" }}>
              <strong>{key}: </strong>
              {typeof value === "object" ? JSON.stringify(value) : value.toString()}
            </Typography>
          ))}
        </Box>
      </TableCell>
    </TableRow>
  );
};

const CTM = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth(); // Access the authenticated user

  const [searchMode, setSearchMode] = useState("general");
  const [keywords, setKeywords] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [darkWebTerms, setDarkWebTerms] = useState({
    email: "",
    username: "",
    password: "",
    lastip: "",
    name: "",
    _domain: "",
  });
  const [darkWebResults, setDarkWebResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openScheduleModal, setOpenScheduleModal] = useState(false);

  // New state for extra functionality
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [searchHistory, setSearchHistory] = useState([]);
  const [filterSource, setFilterSource] = useState("All");

  const FLASK_API_URL = "https://139.144.31.16:8891";
  // Cloud Function URL (ensure this is correctly set)
  const CLOUD_FUNCTION_URL = "https://proxysnusbase-ydqv7zdweq-uc.a.run.app";

  const handleSearch = async () => {
    setError("");
    setLoading(true);

    if (searchMode === "general") {
      if (!keywords.trim()) {
        setError("Please enter keywords for searching.");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.post(`${FLASK_API_URL}/search`, { keywords });
        const data = response.data || {};
        console.log("API Response:", data);

        // Convert the object of arrays into a structure our UI can map over easily
        const resultsArray = Object.entries(data).map(([source, results]) => ({
          source,
          results: Array.isArray(results) ? results : [],
        }));

        setSearchResults(resultsArray);

        // Save search term in history if not already present
        setSearchHistory((prev) => {
          if (!prev.includes(keywords)) {
            return [...prev, keywords];
          }
          return prev;
        });

        setSnackbar({
          open: true,
          message: "Search completed successfully!",
          severity: "success",
        });
      } catch (err) {
        console.error("Error during search:", err);
        setError(err.response?.data?.error || "An error occurred during search.");
        setSnackbar({
          open: true,
          message: "Error during search. Please try again.",
          severity: "error",
        });
      }
    } else if (searchMode === "darkweb") {
      const terms = Object.entries(darkWebTerms)
        .filter(([_, value]) => value.trim() !== "")
        .map(([key, value]) => ({ type: key, term: value }));

      if (terms.length === 0) {
        setError("Please enter at least one search field.");
        setLoading(false);
        return;
      }

      if (!currentUser) {
        setError("You must be logged in to perform a dark web search.");
        setLoading(false);
        return;
      }

      try {
        const idToken = await currentUser.getIdToken();

        const response = await axios.post(
          CLOUD_FUNCTION_URL,
          {
            terms: terms.map((t) => t.term),
            types: terms.map((t) => t.type),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
          }
        );

        if (response.data && response.data.results) {
          const parsedResults = Object.values(response.data.results).flat();
          setDarkWebResults(parsedResults);
          setSnackbar({
            open: true,
            message: "Dark web search completed!",
            severity: "success",
          });
        } else {
          setError("No dark web results found.");
        }
      } catch (err) {
        console.error("Error during dark web search:", err);
        setError(err.response?.data?.error || "An error occurred while fetching dark web data.");
        setSnackbar({
          open: true,
          message: "Error during dark web search.",
          severity: "error",
        });
      }
    }

    setLoading(false);
  };

  const handleExportCSV = () => {
    if (darkWebResults.length === 0) {
      setError("No dark web data available to export.");
      setSnackbar({
        open: true,
        message: "No dark web data available to export.",
        severity: "error",
      });
      return;
    }
    const csvContent = [
      Object.keys(darkWebResults[0] || {}).join(","),
      ...darkWebResults.map((row) =>
        Object.values(row)
          .map((value) => `"${value !== undefined ? value.toString().replace(/"/g, '""') : ""}"`)
          .join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "darkweb_results.csv");
    link.style.visibility = "hidden";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setSnackbar({
      open: true,
      message: "CSV exported successfully!",
      severity: "success",
    });
  };

  const handleScheduleScan = async (searchTerm, email, dateTime) => {
    setError("");
    try {
      const response = await axios.post(`${FLASK_API_URL}/schedule-scan`, {
        email,
        searchTerm,
        dateTime,
      });

      if (response.data?.message) {
        alert(response.data.message);
      } else {
        setError("Unexpected response from the server.");
      }
    } catch (err) {
      console.error("Error scheduling scan:", err.response?.data || err.message);
      setError("Failed to schedule the scan. Please check your inputs and try again.");
    }
  };

  // Handle snackbar close
  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // Function to trigger search from history item
  const handleHistoryClick = (term) => {
    setKeywords(term);
    handleSearch();
  };

  // Build list of filter options based on searchResults sources
  const availableSources = ["All", ...new Set(searchResults.map((group) => group.source))];

  return (
    <Box
      sx={{
        p: 4,
        minHeight: "100vh",
        bgcolor: "background.default",
        color: "text.primary",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <IconButton
        onClick={() => navigate("/")}
        sx={{
          alignSelf: "flex-start",
          mb: 2,
        }}
      >
        <ArrowBackIcon sx={{ fontSize: "2rem", color: "#e63946" }} />
      </IconButton>

      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Typography
          variant="h3"
          sx={{
            mb: 4,
            textAlign: "center",
            fontFamily: "'Montserrat', sans-serif",
            fontWeight: 700,
            background: "linear-gradient(135deg, #e63946, #f4a261)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          CTM (Cyber Threat Monitoring)
        </Typography>
      </motion.div>

      {/* Search Mode Selector */}
      <FormControl sx={{ mb: 3, width: "100%", maxWidth: "1200px" }}>
        <InputLabel>Search Mode</InputLabel>
        <Select
          value={searchMode}
          onChange={(e) => setSearchMode(e.target.value)}
        >
          <MenuItem value="general">General Search</MenuItem>
          <MenuItem value="darkweb">Dark Web Monitoring</MenuItem>
        </Select>
      </FormControl>

      {/* Search History for General Search */}
      {searchMode === "general" && searchHistory.length > 0 && (
        <Box
          sx={{
            mb: 2,
            width: "100%",
            maxWidth: "600px",
            display: "flex",
            gap: 1,
            flexWrap: "wrap",
          }}
        >
          {searchHistory.map((term, index) => (
            <Chip
              key={index}
              label={term}
              onClick={() => handleHistoryClick(term)}
              sx={{ backgroundColor: "#f4a261", color: "#000" }}
            />
          ))}
        </Box>
      )}

      {/* Filter Selector for General Search Results */}
      {searchMode === "general" && searchResults.length > 0 && (
        <FormControl sx={{ mb: 3, width: "100%", maxWidth: "1200px" }}>
          <InputLabel>Filter by Source</InputLabel>
          <Select
            value={filterSource}
            onChange={(e) => setFilterSource(e.target.value)}
          >
            {availableSources.map((source, idx) => (
              <MenuItem key={idx} value={source}>
                {source}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {/* General or Dark Web Inputs */}
      {searchMode === "general" ? (
        <Box sx={{ mb: 3, width: "100%", maxWidth: "600px" }}>
          <TextField
            fullWidth
            label="Enter Keywords for Search"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
            variant="outlined"
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: "grid",
            gap: 2,
            mb: 4,
            width: "100%",
            maxWidth: "600px",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          {Object.keys(darkWebTerms).map((type) => (
            <TextField
              key={type}
              label={`Search by ${type}`}
              value={darkWebTerms[type]}
              onChange={(e) =>
                setDarkWebTerms((prev) => ({
                  ...prev,
                  [type]: e.target.value,
                }))
              }
            />
          ))}
        </Box>
      )}

      {/* Action Buttons with Tooltips */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
          mt: 2,
          mb: 3,
        }}
      >
        <Tooltip title="Schedule a scan for later">
          <Button
            variant="contained"
            onClick={() => setOpenScheduleModal(true)}
            sx={{
              backgroundColor: "#e63946",
              "&:hover": { backgroundColor: "#c72c3e" },
            }}
          >
            Schedule Scan
          </Button>
        </Tooltip>
        <Tooltip title="Start your search now">
          <Button
            variant="contained"
            onClick={handleSearch}
            disabled={loading}
            sx={{
              backgroundColor: "#e63946",
              "&:hover": { backgroundColor: "#c72c3e" },
            }}
          >
            Search
          </Button>
        </Tooltip>
      </Box>

      <ScheduleScanModal
        open={openScheduleModal}
        handleClose={() => setOpenScheduleModal(false)}
        handleSchedule={handleScheduleScan}
      />

      {/* Loading & Error Alerts */}
      {loading && (
        <Box
          sx={{
            mt: 4,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <DecryptingText text="SCANNING THE DARK WEB AND EXTRACTING INTEL..." />
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mt: 4, mb: 4, width: "100%", maxWidth: "1200px" }}>
          {error}
        </Alert>
      )}

      {/* GENERAL SEARCH RESULTS TABLE using ResultRow */}
      {searchMode === "general" && searchResults.length > 0 && (
        <>
          <Typography variant="h6" sx={{ mb: 2 }}>
            General Search Results:
          </Typography>
          <TableContainer component={Paper} sx={{ mb: 4, width: "100%", maxWidth: "1200px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Source</TableCell>
                  <TableCell>Details</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searchResults.map(({ source, results }, groupIndex) => {
                  if (filterSource !== "All" && source !== filterSource) return null;
                  return results.map((result, idx) => (
                    <ResultRow key={`${groupIndex}-${idx}`} source={source} result={result} />
                  ));
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {/* DARK WEB RESULTS */}
      {searchMode === "darkweb" && darkWebResults.length > 0 && (
        <>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Dark Web Results:
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3, width: "100%", maxWidth: "1200px" }}>
            <Button
              variant="contained"
              onClick={handleExportCSV}
              sx={{
                backgroundColor: "#e63946",
                "&:hover": { backgroundColor: "#c72c3e" },
              }}
            >
              Export CSV
            </Button>
          </Box>
          <TableContainer component={Paper} sx={{ width: "100%", maxWidth: "1200px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  {darkWebResults[0] &&
                    Object.keys(darkWebResults[0]).map((key) => (
                      <TableCell key={key}>
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {darkWebResults.map((result, index) => (
                  <TableRow key={index}>
                    {Object.values(result).map((value, idx) => (
                      <TableCell key={idx}>
                        {typeof value === "string" && value.startsWith("http") ? (
                          <a
                            href={value}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "#e63946", textDecoration: "underline" }}
                          >
                            {value}
                          </a>
                        ) : (
                          value || "N/A"
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      {/* Snackbar Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: "100%" }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CTM;
